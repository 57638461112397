.about {
  position: relative;
  background-image: url(./assets/bg.jpg);
  background-repeat: repeat;
  background-size: cover;
  padding: 20px 0 10px;
  scroll-margin-top: 100px;

  @media (max-width: 991px) {
    scroll-margin-top: 50px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 30px;
  }

  &__top {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;

    transform: translate(0, -100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;

    transform: translate(0, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__img {
    flex: 0 0 auto;
    width: 376px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin: 26px 0 0;
      width: 194px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &Body {
    display: flex;
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 60px;

    @media (max-width: 767px) {
      margin: 0;
      align-items: center;
    }

    &__title {
      @media (max-width: 767px) {
        text-align: center;
      }
    }
    &__descr {
      margin: 52px 0 0;

      color: #fff;
      font-family: Bahnschrift;
      font-size: 20px;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 767px) {
        margin: 22px 0 0;
        font-size: 16px;
      }
    }
    &__btn {
      margin: 52px 0 0;

      @media (max-width: 767px) {
        margin: 26px 0 0;
      }
    }
  }
}
