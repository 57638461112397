.faq {
  padding: 20px 0 170px;
  position: relative;
  z-index: 1;
  background-image: url(../About/assets/bg.jpg);
  background-repeat: repeat;
  background-size: contain;

  scroll-margin-top: 100px;

  @media (max-width: 991px) {
    scroll-margin-top: 50px;
  }

  @media (max-width: 767px) {
    padding: 20px 0 40px;
  }

  .con {
    @media (max-width: 1200px) {
      overflow: hidden;
    }
  }

  &__pistols {
    position: absolute;
    z-index: 3;
    top: -190px;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
      width: 304px;
      top: -75px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__top {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;

    transform: translate(0, -100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;

    transform: translate(0, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    text-align: center;
    align-items: center;

    svg {
      margin: 0 16px;

      @media (max-width: 850px) {
        margin: 0 6px;
        width: 22px;
        height: 22px;
      }
    }
  }

  &Body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 736px;
    margin: 50px auto 0;

    @media (max-width: 767px) {
      margin: 20px auto 0;
    }

    &__shots {
      pointer-events: none;
      position: absolute;
      top: 16px;
      left: -128px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
