.card {
  width: 352px;
  height: 500px;
  background-image: url(./assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 46px 30px 15px 24px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 268px;
    height: 380px;
    padding: 35px 20px 10px;
  }

  &__title {
    color: #333;
    text-align: center;
    font-size: 44.568px;
    line-height: 90%;
    text-transform: uppercase;

    @media (max-width: 1200px) {
      font-size: 30px;
    }
  }

  &Content {
    flex: 1 1 auto;
    margin: 34px 0 0;
    display: flex;
    height: auto;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1200px) {
      margin: 22px 0 0;
    }

    &__item {
      position: relative;
      padding: 0 0 0 52px;

      color: #333;
      font-family: Bahnschrift;
      font-size: 24px;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 1200px) {
        padding: 0 0 0 38px;
        font-size: 18px;
      }

      &:not(:first-child) {
        margin: 26px 0 0;

        @media (max-width: 1200px) {
          margin: 20px 0 0;
        }
      }

      svg {
        position: absolute;
        left: 0;
        top: 6px;

        @media (max-width: 1200px) {
          top: 3px;
          width: 28px;
        }
      }
    }
  }
}
