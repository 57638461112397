.community {
  position: relative;
  background-image: url(../About/assets/bg.jpg);
  background-repeat: repeat;
  background-size: cover;
  padding: 40px 0 10px;
  scroll-margin-top: 100px;

  @media (max-width: 991px) {
    scroll-margin-top: 50px;
  }

  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }

  &__pistols {
    position: absolute;
    z-index: 3;
    top: -190px;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
      width: 304px;
      top: -75px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__top {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;

    transform: translate(0, -100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;

    transform: translate(0, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &Body {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      text-align: center;
      align-items: center;

      svg {
        margin: 0 16px;

        @media (max-width: 850px) {
          margin: 0 6px;
          width: 22px;
          height: 22px;
        }
      }
    }
    &__text {
      margin: 28px 0 0;

      color: #fff;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      p {
        &:not(:first-child) {
          margin: 32px 0 0;

          @media (max-width: 767px) {
            margin: 20px 0 0;
          }
        }
      }
    }
    &__btn {
      margin: 44px auto 0;

      @media (max-width: 767px) {
        margin: 20px auto 0;
      }

      & > span {
        padding: 0 60px !important;
        @media (max-width: 767px) {
          padding: 0 50px !important;
        }
      }
    }
  }
}
