.how {
  position: relative;
  background-image: url(../About/assets/bg.jpg);
  background-repeat: repeat;
  background-size: cover;
  padding: 25px 0 10px;

  @media (max-width: 767px) {
    padding: 20px 0 30px;
  }

  &__top {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;

    transform: translate(0, -100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;

    transform: translate(0, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    text-align: center;
    align-items: center;

    svg {
      margin: 0 16px;

      @media (max-width: 850px) {
        margin: 0 6px;
        width: 22px;
        height: 22px;
      }
    }
  }
  &Body {
    position: relative;
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 50px auto 0;

    &__shot {
      position: absolute;
      top: -100px;
      right: 0;
    }
  }
}
