.dont {
  background-image: url(./assets/bg.jpg);
  background-size: cover;
  background-position: center;
  padding: 170px 0;

  @media (max-width: 850px) {
    padding: 80px 0 90px;
  }

  &Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0;
    margin: 0 auto;
    width: 800px;
    height: 370px;
    background-image: url(./assets/card-bg.jpg);
    background-size: cover;
    background-position: center;

    @media (max-width: 850px) {
      background-image: url(./assets/card-bg-mobile.jpg);
      padding: 12px 0 15px;
      width: 286px;
      height: 242px;
    }

    &__title {
      text-align: center;
      align-items: center;

      @media (max-width: 850px) {
        font-size: 23px;
      }

      svg {
        margin: 0 16px;

        @media (max-width: 850px) {
          margin: 0 6px;
          width: 22px;
          height: 22px;
        }
      }
    }
    &__text {
      margin: 40px auto 0;
      max-width: 640px;

      color: #333;
      text-align: center;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      font-family: Bahnschrift;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 850px) {
        margin: 8px auto 0;
        font-size: 14px;
      }
    }
    &__btn {
      margin: 40px 0 0;

      @media (max-width: 850px) {
        margin: 12px auto 0;
      }

      & > span {
        padding: 0 60px !important;

        @media (max-width: 767px) {
          padding: 0 50px !important;
        }
      }
    }
  }
}
