@font-face {
  font-family: Bahnschrift;
  font-display: swap;
  src: url('./assets/fonts/Bahnschrift-Regular.woff2') format('woff2'),
    url('./assets/fonts/Bahnschrift-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Rye;
  font-display: swap;
  src: url('./assets/fonts/Rye-Regular.woff2') format('woff2'),
    url('./assets/fonts/Rye-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Bebas Neue;
  font-display: swap;
  src: url('./assets/fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('./assets/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
