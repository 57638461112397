.tokenomics {
  padding: 160px 0 110px;

  background-image: url(./assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  scroll-margin-top: 100px;

  @media (max-width: 991px) {
    scroll-margin-top: 50px;
  }

  @media (max-width: 850px) {
    padding: 64px 0 190px;
  }

  &Body {
    &__title {
      text-align: center;
      align-items: center;

      svg {
        margin: 0 16px;

        @media (max-width: 850px) {
          margin: 0 6px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &Card {
    position: relative;
    margin: 60px auto 0;
    width: 800px;
    height: 564px;
    background-image: url(./assets/card-bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;

    padding: 56px 0 0;

    @media (max-width: 850px) {
      margin: 20px auto 0;
      width: 292px;
      height: 205px;
      padding: 14px 0 0;
    }

    &__shots {
      position: absolute;
      z-index: 2;
      top: 100px;
      left: 87px;

      @media (max-width: 850px) {
        top: 34px;
        left: 24px;
      }
    }

    &__title {
      color: #333;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 44.568px;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 850px) {
        font-size: 17px;
      }
    }
    &__value {
      margin: 25px 0 0;

      color: #333;
      text-align: center;
      font-family: Rye;
      font-size: 66.852px;
      line-height: 100%;
      text-transform: uppercase;

      @media (max-width: 850px) {
        margin: 10px 0 0;
        font-size: 25px;
      }
    }
    &__locked {
      position: relative;
      z-index: 1;
      margin: 40px auto 0;

      color: #fff;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 33.426px;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 850px) {
        margin: 20px auto 0;
        font-size: 12px;
      }

      svg {
        position: absolute;
        z-index: -1;
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 850px) {
          width: 175px;
        }
      }
    }
    &__tax {
      position: relative;
      margin: 50px auto 0;

      color: #333;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 44.568px;
      font-weight: 700;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 850px) {
        margin: 20px 0 0;
        font-size: 17px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 460px;
        height: 3px;
        background: #4c4544;
        bottom: -24px;
        left: 50%;
        transform: translate(-50%, 0);

        @media (max-width: 850px) {
          width: 176px;
          height: 1px;
          bottom: -10px;
        }
      }
    }
    &__label {
      margin: 54px auto 0;

      color: #333;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 33.426px;
      font-weight: 400;
      line-height: 90%;
      text-transform: uppercase;

      @media (max-width: 850px) {
        margin: 20px auto 0;
        font-size: 17px;
      }
    }

    &__person1 {
      position: absolute;
      left: -305px;
      bottom: -190px;

      @media (max-width: 850px) {
        width: 160px;
        left: 7px;
        bottom: -240px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &__person2 {
      position: absolute;
      right: -180px;
      bottom: 0;

      @media (max-width: 850px) {
        display: none;
      }
    }
  }
}
