.header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: #7a6657;

  background-image: url(./assets/bg.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: repeat;

  &::after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;

    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.27) -0.16%,
        rgba(0, 0, 0, 0.27) 45.71%,
        rgba(0, 0, 0, 0) 46.22%,
        rgba(0, 0, 0, 0.27) 100%
      ),
      #febe63;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
  }

  &Body {
    position: relative;
    z-index: 1;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
      height: 40px;
    }

    &__logo {
      max-width: 352px;

      @media (max-width: 1200px) {
        max-width: 170px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__links {
      display: flex;
      align-items: center;
      gap: 0 35px;

      &Item {
        cursor: pointer;
        position: relative;
        color: #fff;
        font-family: Bahnschrift;
        font-size: 16px;
        line-height: 100%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -6px;
          width: 0;
          height: 3px;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.27) -0.16%,
              rgba(0, 0, 0, 0.27) 45.71%,
              rgba(0, 0, 0, 0) 46.22%,
              rgba(0, 0, 0, 0.27) 100%
            ),
            #febe63;
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
          right: 0;
          transition: all 0.3s ease;
        }

        &:hover {
          &::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }
      }
    }
    &__btn {
    }

    &__burger {
      position: relative;
      z-index: 2;
      width: 20px;
      height: 20px;

      span {
        display: block;
        width: 100%;
        height: 4px;

        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.27) -0.16%,
            rgba(0, 0, 0, 0.27) 45.71%,
            rgba(0, 0, 0, 0) 46.22%,
            rgba(0, 0, 0, 0.27) 100%
          ),
          #febe63;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

        position: absolute;

        &:nth-child(1) {
        }
        &:nth-child(2) {
          top: 50%;
          transform: translate(0, -50%);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }

    &__menu {
      position: absolute;
      z-index: 1;
      top: 0;
      right: -16px;
      width: 164px;
      padding: 30px 16px 16px;
      background-color: rgba(0, 0, 0, 0.9);

      display: flex;
      flex-direction: column;
      gap: 12px;

      opacity: 0;
      transform: translate(0);
      pointer-events: none;
      visibility: hidden;
      transition: all 0.4s ease;

      &._open {
        opacity: 1;
        transform: translate(0);
        pointer-events: unset;
        visibility: visible;
      }

      &Item {
        color: #fff;
        font-family: Bahnschrift;
        font-size: 20px;
        font-weight: 300;
        line-height: 100%;
      }
    }
  }
}
