.team {
  position: relative;
  background-image: url(../About/assets/bg.jpg);
  background-repeat: repeat;
  background-size: cover;
  padding: 40px 0 10px;
  scroll-margin-top: 100px;

  @media (max-width: 991px) {
    scroll-margin-top: 50px;
  }

  @media (max-width: 1200px) {
    padding: 30px 0 30px;
  }

  .con {
    @media (max-width: 1200px) {
      overflow: hidden;
    }
  }

  &__shots {
    position: absolute;
    z-index: 1;
    top: 70px;
    left: calc(50% - 80px);
    transform: translate(-50%, 0);

    @media (max-width: 1600px) {
      width: 1400px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    @media (max-width: 1300px) {
      display: none;
    }
  }

  &__top {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;

    transform: translate(0, -100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;

    transform: translate(0, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    text-align: center;
    align-items: center;

    svg {
      margin: 0 16px;

      @media (max-width: 850px) {
        margin: 0 6px;
        width: 22px;
        height: 22px;
      }
    }
  }

  &Body {
    display: flex;
    margin: 34px 0 0;
    gap: 0 38px;

    &__item {
      flex: 0 0 calc(25% - 24px);

      @media (max-width: 1200px) {
        width: 256px;
      }
    }

    &__img {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__name {
      margin: 18px 0 0;
      color: #333;
      text-align: center;
      font-family: Rye;
      font-size: 30px;
      font-weight: 400;
      line-height: 100%;
      text-transform: uppercase;
    }

    &__pos {
      margin: 5px 0 0;

      color: #333;
      text-align: center;
      font-family: Bahnschrift;
      font-size: 20px;
      font-weight: 300;
      line-height: 100%;
    }
  }

  &Slider {
    margin: 15px 0 0;
    overflow: visible !important;

    &__item {
      width: 256px !important;
    }
  }
}
